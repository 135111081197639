.chips__result {
  float: left;
  padding-left: 16px;
  color: $b-mc-med;
  background-color: transparent;
  @include txt--s($main-font);
  text-transform: capitalize;
  display: flex;
  align-items: center;
  border-radius: 16px;
  box-shadow: 0 0 0 2px inset $b-mc-med;
  position: relative;
  margin: 8px 8px 0 0;

  .chips__result__text {
    margin: 0 8px 0 0;
    transition: $tr--s;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: calc(100vw - 135px);

    &:after {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      box-shadow: 0 0 0 2px inset transparent;
      border-radius: 16px;
      transition: $tr--s;
    }
  }

  .chips__result__button {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    cursor: pointer;
    background: $b-mc-med;
    border: none;
    border-radius: $border-rds--circle;
    position: relative;
    z-index: 2;
    order: 2;

    svg {
      width: 16px;
      height: 16px;
      fill: $b-nc-white;
      transition: $tr--s;
      transform-origin: center;
    }

    &:hover,
    &:focus {
      background-color: $b-mc-light;
      svg {
        transform: scale(1.1) rotate(90deg);
      }
    }
  }

  .chips__result__button:hover + .chips__result__text {
    color: $b-mc-light;

    &:after {
      box-shadow: 0 0 0 2px inset $b-mc-light;
    }
  }

  @media (min-width: $screen-xs) {
    .chips__result__text {
      max-width: auto;
    }
  }
}

///// Chips Info /////
.chips {
  display: inline-flex;
  align-items: center;
  @include txt--s($main-font);
  white-space: nowrap;
  position: relative;

  &--info {
    border-radius: 20px;
    padding: 4px 12px 4px 12px;
    border: 1px solid $b-nc-85;
    svg {
      width: 20px;
      height: auto;
      margin-right: 4px;
    }
    &--positive {
      border-color: $b-uc-positive;
      background-color: rgba(0, 194, 163, 0.15);
      svg {
        stroke: $b-uc-positive;
      }
    }
    &--caution {
      border-color: $b-uc-caution;
      background-color: rgba(255, 135, 0, 0.15);
      svg {
        stroke: $b-uc-caution;
      }
    }
    &--warning {
      border-color: $b-uc-warning;
      background-color: rgba(237, 2, 10, 0.15);
      svg {
        stroke: $b-uc-warning;
      }
    }
    &--neutral {
      border-color: $b-ac-3;
      background-color: rgba($b-ac-3, 0.15);
      svg {
        stroke: $b-ac-3;
      }
    }
  }

  &--status {
    border-radius: 15px;
    padding: 4px 16px 4px 16px;
    color: #ffffff;
  }
}

.chips-filter {
  position: relative;
  display: inline-block;
  padding: 0.3rem 1.75rem 0.25rem 0.75rem;
  font-size: 0.75rem;
  text-align: center;
  color: $b-mc-med;
  font-weight: 400;
  border: 1px solid $b-mc-med;
  border-radius: 3rem;

  button {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1.5rem;
    height: calc(100% + 2px);
    background: $b-mc-med;
    border: none;
    border-radius: 0 3rem 3rem 0;
    cursor: pointer;
    transform: translate(1px, -1px);
    transition: $tr--m;

    &:hover,
    &:focus {
      background: $b-mc-light;
      transition: $tr--m;
    }

    svg {
      position: absolute;
      top: 50%;
      right: 50%;
      width: 0.75rem;
      height: 0.75rem;
      transform: translate(50%, -50%);
    }
  }
}
